var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[(_vm.settings)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","lg":"6","xl":"4","offset-md":"2","offset-lg":"3","offset-xl":"4"}},[_c('h1',{staticClass:"h2 itb-headline mb-0 text-center"},[_vm._v("\n                    "+_vm._s(_vm.settings.event_name)+"\n                    "),_c('span',{staticClass:"itb-text-muted"},[_vm._v(_vm._s(_vm.settings.event_date))])])])],1):_vm._e()],1),_vm._v(" "),(_vm.settings)?_c('div',{staticClass:"itb-action-buttons mt-3"},[_c('v-container',{staticClass:"p-0"},[_c('v-row',[_c('v-col',{staticClass:"px-4",attrs:{"cols":"12","md":"8","lg":"6","xl":"4","offset-md":"2","offset-lg":"3","offset-xl":"4"}},[(_vm.shownTab == 2)?_c('v-btn',{staticClass:"float-left",attrs:{"color":"secondary","small":""},on:{"click":function($event){_vm.shownTab = 1;
                            _vm.teamId = null;}}},[_c('v-icon',{staticClass:"mr-1 text-white"},[_vm._v("mdi-arrow-left")]),_vm._v("\n                        "+_vm._s(_vm.$t("results.overall_evaluation"))+"\n                    ")],1):_vm._e(),_vm._v(" "),_c('v-btn',{staticClass:"float-right",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.refreshResults()}}},[_c('v-icon',{staticClass:"mr-1 text-white"},[_vm._v("mdi-reload")]),_vm._v("\n                        "+_vm._s(_vm.$t("results.reload_results"))+"\n                    ")],1)],1)],1)],1)],1):_vm._e(),_vm._v(" "),_c('v-container',[(_vm.settings)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","lg":"6","xl":"4","offset-md":"2","offset-lg":"3","offset-xl":"4"}},[(_vm.shownTab == 1)?_c('div',[_c('h2',{staticClass:"h4 itb-headline mt-5 text-center"},[_vm._v("\n                        "+_vm._s(_vm.$t("results.overall_evaluation"))+"\n                    ")]),_vm._v(" "),_c('v-alert',{staticClass:"mb-3 mt-5 text-center p-1",staticStyle:{"font-size":"100%"},attrs:{"text":"","outlined":"","color":"blue darken-4"},domProps:{"innerHTML":_vm._s(
                            _vm.$t('results.evaluation_rules_1') +
                                _vm.settings.counted_stands +
                                _vm.$t('results.evaluation_rules_2') +
                                _vm.settings.counted_stands +
                                _vm.$t('results.evaluation_rules_3')
                        )}}),_vm._v(" "),_c('v-card',{staticClass:"mt-5",attrs:{"loading":_vm.isAllTeamsResultsLoading}},[_c('v-card-text',{staticClass:"p-0"},[_c('div',{staticClass:"itb-search-in-table d-flex"},[_c('v-text-field',{attrs:{"label":_vm.$t('results.filter_by_team_name'),"solo":"","dense":"","prepend-icon":"mdi-magnify","clearable":"","hide-details":""},model:{value:(_vm.filterString),callback:function ($$v) {_vm.filterString=$$v},expression:"filterString"}})],1),_vm._v(" "),_c('v-simple-table',{staticClass:"itb-densed-table",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"px-2"},[_vm._v("\n                                                "+_vm._s(_vm.$t("results.rank"))+"\n                                            ")]),_vm._v(" "),_c('th',{staticClass:"text-center px-2"},[_vm._v("\n                                                "+_vm._s(_vm.$t("results.team"))+"\n                                            ")]),_vm._v(" "),_c('th',{staticClass:"text-center px-2"},[_vm._v("\n                                                "+_vm._s(_vm.$t("results.status"))+"\n                                            ")]),_vm._v(" "),_c('th',{staticClass:"text-center px-2"},[_vm._v("\n                                                "+_vm._s(_vm.$t("results.points"))+"\n                                            ")]),_vm._v(" "),_c('th')])]),_vm._v(" "),_c('tbody',_vm._l((_vm.filteredRows),function(team,index){return _c('tr',{key:("team-" + index)},[_c('td',{staticClass:"px-2"},[_vm._v("\n                                                "+_vm._s(team.rank)+"\n                                                "),(
                                                        team.rank > 0 &&
                                                            team.rank <= 3
                                                    )?_c('v-icon',{style:('color: ' +
                                                            _vm.getRankColor(
                                                                team.rank
                                                            ))},[_vm._v("mdi-crown")]):_vm._e()],1),_vm._v(" "),_c('td',{staticClass:"text-center px-2"},[_vm._v("\n                                                "+_vm._s(team.name)+"\n                                            ")]),_vm._v(" "),_c('td',{staticClass:"text-center px-2"},[_c('v-chip',{staticClass:"px-2",attrs:{"small":"","label":"","color":_vm.getStatusColor(
                                                            team.status
                                                        ),"text-color":"white"}},[_vm._v(_vm._s(_vm.getStatusLabel(
                                                            team.status
                                                        )))])],1),_vm._v(" "),_c('td',{staticClass:"text-center px-2"},[_vm._v("\n                                                "+_vm._s(team.total)+"\n                                            ")]),_vm._v(" "),_c('td',{staticClass:"p-0",staticStyle:{"width":"20px"}},[_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.showTeamResults(
                                                            team.id
                                                        )}}},[_c('v-icon',[_vm._v("mdi-account-search")])],1)],1)])}),0)]},proxy:true}],null,false,1276621441)})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.team && _vm.shownTab == 2)?_c('div',[_c('h2',{staticClass:"h4 itb-headline mt-5 text-center"},[_vm._v("\n                        "+_vm._s(_vm.$t("results.basic_info"))+"\n                    ")]),_vm._v(" "),_c('v-card',{staticClass:"mb-3",attrs:{"loading":_vm.isTeamResultsLoading}},[_c('v-card-text',{staticClass:"p-0"},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"4"}},[_c('div',{staticClass:"mb-2"},[_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-account-group")])],1),_vm._v(" "),_c('div',{staticClass:"purple--text font-weight-bold mb-1",staticStyle:{"font-size":"1.7rem"}},[_vm._v("\n                                        "+_vm._s(_vm.team.name)+"\n                                    ")]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.$t("results.team")))])]),_vm._v(" "),_c('v-col',{staticClass:"text-center",attrs:{"cols":"4"}},[_c('div',{staticClass:"mb-2"},[_c('v-icon',{style:('color: ' +
                                                    _vm.getRankColor(_vm.team.rank))},[_vm._v("mdi-crown")])],1),_vm._v(" "),_c('div',{staticClass:"font-weight-bold mb-1",style:('color: ' +
                                                _vm.getRankColor(_vm.team.rank) +
                                                '; font-size: 1.7rem')},[_vm._v("\n                                        "+_vm._s(_vm.team.rank)+"\n                                    ")]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.$t("results.rank")))])]),_vm._v(" "),_c('v-col',{staticClass:"text-center",attrs:{"cols":"4"}},[_c('div',{staticClass:"mb-2"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-counter")])],1),_vm._v(" "),_c('div',{staticClass:"green--text font-weight-bold mb-1",staticStyle:{"font-size":"1.7rem"}},[_vm._v("\n                                        "+_vm._s(_vm.team.total)+"\n                                    ")]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.$t("results.total")))])])],1)],1)],1),_vm._v(" "),_c('h2',{staticClass:"h4 itb-headline mt-5 text-center"},[_vm._v("\n                        "+_vm._s(_vm.$t("results.stands_points"))+"\n                    ")]),_vm._v(" "),_c('v-alert',{staticClass:"mb-3 mt-5 text-center p-1",staticStyle:{"font-size":"100%"},attrs:{"text":"","outlined":"","color":"blue darken-4"},domProps:{"innerHTML":_vm._s(
                            _vm.$t('results.evaluation_rules_1') +
                                _vm.settings.counted_stands +
                                _vm.$t('results.evaluation_rules_2') +
                                _vm.settings.counted_stands +
                                _vm.$t('results.evaluation_rules_3')
                        )}}),_vm._v(" "),_c('v-card',{attrs:{"loading":_vm.isTeamResultsLoading}},[_c('v-card-text',{staticClass:"p-0"},[_c('v-simple-table',{staticClass:"itb-densed-table",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"px-2"},[_vm._v("\n                                                "+_vm._s(_vm.$t("results.stand"))+"\n                                            ")]),_vm._v(" "),_vm._l((_vm.team.teamMembers),function(member){return _c('th',{key:member.id,staticClass:"text-center px-2"},[_vm._v("\n                                                "+_vm._s(member.name)+"\n                                            ")])}),_vm._v(" "),_c('th',{staticClass:"text-center px-2"},[_vm._v("\n                                                "+_vm._s(_vm.$t("results.average"))+"\n                                            ")])],2)]),_vm._v(" "),_c('tbody',_vm._l((_vm.team.stands),function(stand,index){return _c('tr',{key:("stand-" + index)},[_c('td',{staticClass:"px-2"},[_vm._v("\n                                                "+_vm._s(index)+".\n                                                "+_vm._s(stand.name)+"\n                                            ")]),_vm._v(" "),_vm._l((_vm.team.teamMembers),function(member){return _c('td',{key:member.id,staticClass:"text-center px-2"},[_vm._v("\n                                                "+_vm._s(stand.members_points &&
                                                    member.id in
                                                        stand.members_points
                                                        ? stand
                                                              .members_points[
                                                              member.id
                                                          ]
                                                        : "-")+"\n                                            ")])}),_vm._v(" "),_c('td',{staticClass:"text-center px-2",staticStyle:{"background":"#E8F5E9"}},[_vm._v("\n                                                "+_vm._s(stand.teamPoints)+"\n                                            ")])],2)}),0)]},proxy:true}],null,false,139833412)})],1)],1)],1):_vm._e()])],1):_vm._e()],1),_vm._v(" "),_c('SnackMessage',{ref:"SnackMessage"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }